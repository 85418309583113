exports.components = {
  "component---src-components-archive-component-tsx": () => import("./../../../src/components/ArchiveComponent.tsx" /* webpackChunkName: "component---src-components-archive-component-tsx" */),
  "component---src-components-books-component-tsx": () => import("./../../../src/components/BooksComponent.tsx" /* webpackChunkName: "component---src-components-books-component-tsx" */),
  "component---src-components-event-tsx": () => import("./../../../src/components/Event.tsx" /* webpackChunkName: "component---src-components-event-tsx" */),
  "component---src-components-variations-component-tsx": () => import("./../../../src/components/VariationsComponent.tsx" /* webpackChunkName: "component---src-components-variations-component-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */)
}

